import React, { useState } from 'react';
import './index.scss'; // Import the CSS for styling
import thumb1 from '../../../images/thumb1.jpeg';
import thumb2 from '../../../images/thumb2.jpeg';
import thumb3 from '../../../images/thumb3.jpeg';
import heroSalt from '../../../images/heroSalt.JPG';
import heroOnion from '../../../images/heroOnion.JPG';
import heroPeri from '../../../images/heroPeri.JPG';
import heroCheese from '../../../images/heroCheese.jpeg';
import heroMint from '../../../images/heroMint.JPG';
import cover from '../../../images/all.png';
import logo from '../../../images/logofinal.png';

const FollowUs = () => {
  const slides = [
    { type: 'reel', url: 'https://www.instagram.com/reel/DDFMyNozTdp/?igsh=MWVrZTR4cHJnY3o5aQ==', thumbnail: thumb1 },
    { type: 'reel', url: 'https://www.instagram.com/reel/DDkAFUrSKDe/?igsh=MWRvdm9ubzgxOGJ2aQ==', thumbnail: thumb2 },
    { type: 'reel', url: 'https://www.instagram.com/reel/DBTDd_IMPqV/?igsh=eTI5ajRqbDkxbmY=', thumbnail: thumb3 },
    { type: 'photo', url: heroSalt },
    { type: 'photo', url: heroOnion },
    { type: 'photo', url: heroPeri },
    { type: 'photo', url: heroCheese },
    { type: 'photo', url: heroMint },
    { type: 'photo', url: cover },
    { type: 'photo', url: logo }
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [playingIndex, setPlayingIndex] = useState(null);

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? Math.ceil(slides.length / 3) - 1 : prev - 1));
    setPlayingIndex(null);
  };

  const nextSlide = () => {
    setCurrentSlide((prev) => {
      const totalSlides = Math.ceil(slides.length / 3);
      return prev === totalSlides - 1 ? 0 : prev + 1;
    });
    setPlayingIndex(null);
  };

  const handleThumbnailClick = (index) => {
    setPlayingIndex(index);
  };

  return (
    <div className="follow-us-container">
      <div className="title">
        <h1 title="Follow us on Instagram">Follow us on Instagram</h1>
        <a href="https://www.instagram.com/farmtimeorganic?igsh=MXF6M3ZhZWRtZTY3eQ==" className="button-link">
          <div className="hyperlink">
            <h4>@farmtimeorganic</h4>
          </div>
        </a>
      </div>

      <div className="follow-us">
        <button className="arrow left-arrow" onClick={prevSlide}>
          &lt;
        </button>

        <div className="slide-container" style={{ '--slide-index': currentSlide }}>
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`slide ${index >= currentSlide * 3 && index < currentSlide * 3 + 3 ? 'active' : ''}`}
            >
              {slide.type === 'reel' ? (
                playingIndex === index ? (
                  <div className="instagram-reel-wrapper">
                    <iframe
                      src={slide.url}
                      width="70%"
                      height="70%"
                      frameBorder="0"
                      allow="autoplay; encrypted-media"
                      allowFullScreen
                      title={`Instagram Reel ${index}`}
                    ></iframe>
                  </div>
                ) : (
                  <div className='thumbnail-wrapper' onClick={() => handleThumbnailClick(index)}>
                    <img
                      className='video-thumbnail'
                      src={slide.thumbnail}
                      alt={`Thumbnail ${index + 1}`}
                    />
                    <div className='play-button-overlay'>
                      <svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 5v14l11-7L8 5z" fill="white"/>
                      </svg>
                    </div>
                  </div>
                )
              ) : (
                <img className='followImage' src={slide.url} alt={`Slide ${index}`} />
              )}
            </div>
          ))}
        </div>

        <button className="arrow right-arrow" onClick={nextSlide}>
          &gt;
        </button>
      </div>
    </div>
  );
};

export default FollowUs;
